.info {
  font-size: 14pt;
}

.heading {
  font-size: 16pt;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20pt;
}

.text-right {
  text-align: right;
  font-weight: bold;
}

.choose-employee-form {
  padding: 40px;
  box-shadow: 0 0 16px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 25px;
}

.w3-light-grey {
  color: #000 !important;
  background-color: #f1f1f1 !important;
}

.w3-red {
  color: #000 !important;
  background-color: rgba(193, 2, 2, 0.8) !important;
  text-align: center;
}

.green {
  color: #fff !important;
  background-color: #0f6b0f !important;
  text-align: center;
}

.w3-round-xlarge {
  height: 26px;
  display: flex;
  text-align: center;
}
