.login-page a {
  cursor: pointer;
}
.login-page .form {
  margin-top: 40px;
  z-index: 1;
  background: #FFFFFF;
  padding: 10px 45px 45px 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.login-page .form h2 {
  margin-bottom: 25px;
  color: #808080;
}
.login-page .form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  transition: box-shadow 400ms ease-in-out;
}
.login-page .form input:focus {
  box-shadow: inset00 20px -0.1px rgba(0, 0, 0, 0.25);
  transition: box-shadow 400ms ease-in-out;
}
.login-page .form input[type=text] {
  text-transform: inherit;
}
.login-page .form input[type=submit] {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #198754;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}
.login-page .form input[type=submit]:hover {
  background: #24bf76;
}
.login-page .form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.login-page .form .message a {
  color: #4CAF50;
  text-decoration: none;
}
.login-page button.login-choice {
  width: 100%;
  background-color: rgba(0, 149, 255, 0.37);
  border: none;
  padding: 8px 15px 8px 15px;
  color: #4a4a4a;
  font-weight: 200;
  margin-bottom: 15px;
}
.login-page button.login-choice:hover {
  background-color: rgba(154, 216, 255, 0.82);
}
