.ellipsis {
  text-overflow: ellipsis;
  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
}

.info {
  font-size: 14pt;
}

.text-right {
  text-align: right;
  font-weight: bold;
}
