html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

a {
  cursor: pointer;
}

.menu {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  justify-content: center;
}
.menu a {
  text-decoration: none;
  color: inherit;
}
.menu a h3 {
  font-size: 20px;
}
.menu .menu-item {
  display: inline-block;
  margin-bottom: 28px;
  margin-right: 28px;
  text-align: center;
  vertical-align: top;
  padding: 15px;
  width: 200px;
  box-shadow: 0 10px 10px -5px #000;
}
.menu .menu-item .menu-icon {
  position: relative;
  top: -15px;
  font-size: 80px;
}

.loginBox {
  margin-top: 25px;
  background-color: #efefef;
  padding: 15px;
}

.showOn-enter {
  transform: scaleX(-50px);
}

.showOn-enter-active {
  transition: transform 500ms;
}

/* For Mobile devices */
@media screen and (max-width: 580px) {
  .employees .employees-box .employee {
    margin-right: 0;
  }
  .menu .menu-item {
    margin-right: 0;
  }
}
/* For Computer devices */
@media screen and (min-width: 860px) {
  .menu .menu-item {
    transition: background-color 400ms ease-in-out, color 400ms ease-in-out, box-shadow 400ms ease-in-out, border 400ms ease-in-out;
  }
  .menu .menu-item:hover {
    background-color: #222;
    color: white;
    cursor: pointer;
    transition: background-color 300ms ease-in-out, color 300ms ease-in-out, box-shadow 400ms ease-in-out, border 200ms ease-in-out;
  }
}
.roles-table th, .roles-table td {
  text-align: center;
}
