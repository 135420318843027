.buildings .register-form {
    position: relative;
    padding: 50px;
    margin-top: 10px;
    background-color: #fefefe;
    box-shadow: rgba(0, 0, 0, 0.3) 0 0 25px 1px;
    margin-bottom: 40px;
}

.buildings .register-form .submit-button {
    margin-top: 30px;
}

.buildings .register-form .close-form {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

.buildings .register-form .close-form:hover {
    color: black;
}


.buildings table.custom-buildings-style thead tr th{
    text-align: center;
}