.roles table {
  border: 0;
}
.roles th.rotate {
  /* Something you can count on */
  height: 140px;
  white-space: nowrap;
  border: 0 solid #ddd;
  width: 20px;
}
.roles th.rotate > div {
  transform: translate(28px, -5px) rotate(-55deg);
  width: 40px;
}
.roles th.rotate > div > span {
  padding: 5px 10px;
  border-bottom: 1px solid #ddd;
}
.roles .showFormBtn {
  margin-bottom: 15px;
}
.roles .roles-table input[type=text] {
  text-align: center;
}
.roles .roles-table .clickable {
  cursor: pointer;
}
.roles .roles-table .clickable:hover {
  transform: scale(1.1, 1.1);
}

.closeBox {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}
.closeBox svg {
  cursor: pointer;
}
.closeBox .closeButton style {
  cursor: pointer;
}
