.employees-detail .employee-box .user-info {
  text-align: center;
}
.employees-detail .employee-box .photo {
  height: 200px;
  border-radius: 50%;
}
.employees-detail .employee-box .info-row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.employees-detail .last-record {
  padding: 25px;
  text-align: center;
  background-color: #eee;
  margin: 20px 15px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.42);
}

.text-right {
  text-align: right;
  font-weight: bold;
}

.text-left {
  text-align: left;
}
