.tablet-detail .owner-panel {

}

.flexy-panel {
    display: flex;
    justify-content: center;
}

.owner-panel .owner-photo {
    height: 200px;
}

.tablet-detail .tablet-info {
    padding: 10px;
    text-align: center;
    background-color: #fcfcfc;
    box-shadow: 0px 0px 5px 0.5px rgba(0,0,0,0.1)
}

.tablet-detail .tablet-history {
    margin-top: 40px;
    margin-bottom: 40px;
}

.tablet-detail .tablet-history > h2 {
    text-align: center;
}
