.employees-detail .info-tabs {
  border: 1px solid black;
}

.closeBox {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}
.closeBox svg {
  cursor: pointer;
}
.closeBox .closeButton style {
  cursor: pointer;
}
