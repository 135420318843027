a {
  cursor: pointer;
}

.groups {
  display: flex;
}
.groups a {
  text-decoration: none;
  color: inherit;
}
.groups .title {
  font-size: 10pt;
  text-wrap: none;
  margin-bottom: 5px;
}
.groups .group-item {
  margin: 6px 0 6px 6px;
  text-align: center;
  vertical-align: top;
  width: 120px;
  border-radius: 10px;
  box-shadow: 0 0 5px 4px rgba(0, 0, 0, 0.31);
}
.groups .group-item .group-icon {
  position: relative;
}
.groups .group-item .group-icon img {
  width: 80px;
  height: 80px;
  margin: 5px;
  border-radius: 50%;
}
.groups .my-droppable {
  background: rgba(184, 209, 255, 0.51);
  display: flex;
  overflow: auto;
  min-height: 30px;
  min-width: 20px;
  margin: 0 15px 15px 15px;
  border-radius: 10px;
}
.groups .new_group {
  background: rgb(255, 255, 255);
  margin: 10px;
  width: 150px;
}
.groups .add_new {
  background: rgb(255, 255, 255);
  border-radius: 15px;
  border-color: #858585;
  border-style: dashed;
  height: 100px;
  margin: 20px;
  text-align: center;
  display: table;
  width: calc(100% - 40px);
}
.groups .drop_text {
  display: table-cell;
  vertical-align: middle;
  font-size: 24pt;
  color: #969696;
}

.icon {
  display: inline-block;
  vertical-align: middle;
}

.icon--plus {
  background: none;
}

.icon--plus > path {
  fill: #ffffff;
  stroke: #ffffff;
  stroke-width: 0.5;
  stroke-linejoin: round;
}

.play-icon {
  color: green;
}
.play-icon:hover {
  cursor: pointer;
}
