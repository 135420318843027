.no-access-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  flex-direction: column;
}
.no-access-page .progress-container {
  width: 50%;
  height: 50px;
  border: 1px solid #eee;
}
.no-access-page .progress-container .progress-bar {
  animation-name: no-access-progress;
  animation-duration: 5s;
  animation-timing-function: linear;
}

@keyframes no-access-progress {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
