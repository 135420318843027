thead th {
  font-size: 11pt;
  text-align: left;
}

td {
  font-size: 11pt;
}

.scaleButton {
  margin: 0 5px;
  width: 38px;
  height: 38px;
}

.file-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input-range__slider {
  appearance: none;
  background: #3f51b5;
  border: 1px solid #3f51b5;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1.5rem;
  margin-left: -0.7rem;
  margin-top: -1rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.1s ease-out, box-shadow 0.1s ease-out;
  width: 1.5rem;
}

.input-range__slider:active {
  transform: scale(1.3);
}

.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}

.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  transform: none; }

.input-range__slider-container {
  transition: left 0.1s ease-out; }

.input-range__label {
  color: rgba(0, 0, 0, 1);
  font-family: "Helvetica Neue", san-serif, serif;
  font-size: 1rem;
  transform: translateZ(0);
  white-space: nowrap; }

.input-range__label--min,
.input-range__label--max {
  bottom: -1rem;
  position: absolute; }

.input-range__label--min {
  left: 0; }

.input-range__label--max {
  right: 0; }

.input-range__label--value {
  position: absolute;
  top: -2.5rem; }

.input-range__label-container {
  left: -50%;
  position: relative; }
.input-range__label--max .input-range__label-container {
  left: 50%; }

.input-range__track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.5rem;
  position: relative;
  transition: left 0.1s ease-out, width 0.1s ease-out; }
.input-range--disabled .input-range__track {
  background: #eeeeee; }

.input-range__track--background {
  left: 0;
  margin-top: 0;
  position: absolute;
  right: 0;
  top: 50%;
}

.input-range__track--active {
  background: #3f51b5; }

.input-range {
  height: 3rem;
  position: relative;
  width: 90%;
  margin-bottom: 1rem;
  margin-left: 10px;
}

.action_button {
  color: green;
  size: 2em;
  cursor: pointer;
}