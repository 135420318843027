.result-table .clickable {
    cursor: pointer;
    text-align: left;
    border-bottom-color: #ccc;
}

.result-table {
    margin-top: 15px;
}

.result-table tr {
    border-color: #ccc;
}

.result-table .search input[type="text"] {
    font-size: 14px;
    height: 40px;
    transition: font-size 300ms ease-in-out, border 300ms ease-in-out;
    margin-bottom: 5px;
    border: 0;
    border-bottom: 1px solid #ccc;
    text-align: left;
}

.result-table .search input[type="text"]:focus {
    transition: font-size 300ms ease-in-out, border 300ms ease-in-out;
    font-size: 20px;
    outline: 0;
    border-bottom: 1.5px solid rgba(38, 146, 219, 0.81)
}

.result-table .search * {
    display: inline-block;
}

.image img {
    height: 35px;
}

.child {
    background-color: #d9e0ee;
}

.left_margin {
    margin-left: 30px;
}

.result-table thead th.sorting-th {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    transition: background-color 300ms ease-in-out;
}

.result-table .no-results {
    display: flex;
    color: #555;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 4em;
}

.result-table thead th.sorting-th:hover {
    background-color: #eee;
}

.left_text_align {
    text-align: left;
}

.result-table td.text {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.result-table .clickable:hover {
     transform: scale(1.1, 1.1)
}

.result-table tbody tr.row-clickable {
    cursor: pointer
}

.result-table tbody tr.row-clickable:hover {
    background-color: #eee;
}

.row-pulsing-err {
  animation-duration: 1s;
  animation-name: pulsing-error;
  animation-iteration-count: infinite;
}

.row-pulsing-err-inset {
  background-color: rgba(255, 0, 0, 0.1);
}

.row-pulsing-activity {
    animation-duration: 3s;
    animation-name: pulsing-error;
    animation-iteration-count: infinite;
}


@keyframes pulsing-error {
   
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255,50,44, 0.6);
    box-shadow: 0 0 0 0 rgba(255,50,44, 0.6);
  }
  70% {
      -moz-box-shadow: 0 0 0 18px rgba(204,169,44, 0);
      box-shadow: 0 0 0 18px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}


@keyframes pulsing-error-inset {
   
  0% {
    -moz-box-shadow:inset 0 0 0 0 rgba(255,50,44, 0.6);
    box-shadow:inset 0 0 0 0 rgba(255,50,44, 0.6);
  }
  70% {
      -moz-box-shadow:inset 0 0 0 9px rgba(204,169,44, 0);
      box-shadow:inset 0 0 0 9px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow:inset 0 0 0 0 rgba(204,169,44, 0);
      box-shadow:inset 0 0 0 0 rgba(204,169,44, 0);
  }
}


@keyframes pulsing-activity-actual {
  0% {
    -moz-box-shadow:  0 0 0 0 rgb(114, 255, 119);
    box-shadow:  0 0 0 0 rgb(114, 255, 119);
  }
  70% {
      -moz-box-shadow: 0 0 0 18px rgba(204,169,44, 0);
      box-shadow: 0 0 0 18px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}

.container.wide {
    max-width: 1920px;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #d3d3d3 !important;
}