.select-style {
  border: 1px solid #ccc;
  border-radius: 3px;
  overflow: hidden;
  margin-right: 10px;
  outline: none;
}

.select-style select {
  padding: 5px 8px;
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
}

.select-style select:focus {
  outline: none;
}
