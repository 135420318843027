.pie {
    border-radius: 100%;
    height: calc(var(--size, 40) * 1px);
    overflow: hidden;
    position: relative;
    width: calc(var(--size, 40) * 1px);
    z-index: 0;
    background: var(--bg, #a2a2a2);
}
.pie__segment {
    --a: calc(var(--over50, 0) * -100%);
    --b: calc((1 + var(--over50, 0)) * 100%);
    --degrees: calc((var(--offset, 0) / 100) * 360);
    -webkit-clip-path: polygon(var(--a) var(--a), var(--b) var(--a), var(--b) var(--b), var(--a) var(--b));
    clip-path: polygon(var(--a) var(--a), var(--b) var(--a), var(--b) var(--b), var(--a) var(--b));
    height: 100%;
    position: absolute;
    -webkit-transform: translate(0, -50%) rotate(90deg) rotate(calc(var(--degrees) * 1deg));
    transform: translate(0, -50%) rotate(90deg) rotate(calc(var(--degrees) * 1deg));
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    width: 100%;
    z-index: calc(1 + var(--over50));
}
.pie__segment:after,
.pie__segment:before {
    background: var(--bg, #e74c3c);
    content: '';
    height: 100%;
    position: absolute;
    width: 100%;
}
.pie__segment:before {
    --degrees: calc((var(--value, 45) / 100) * 360);
    -webkit-transform: translate(0, 100%) rotate(calc(var(--degrees) * 1deg));
    transform: translate(0, 100%) rotate(calc(var(--degrees) * 1deg));
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
}
.pie__segment:after {
    opacity: var(--over50, 0);
}
