.table-fixed {
    width: 100%
}

.table-fixed > thead,
.table-fixed > tbody,
.table-fixed > thead > tr,
.table-fixed > tbody > tr,
.table-fixed > thead > tr > th,
.table-fixed > tbody > tr > td {
    display: block;
}

.table-fixed > tbody > tr:after,
.table-fixed > thead > tr:after {
    content: ' ';
    display: block;
    visibility: hidden;
    clear: both;
}

.table-fixed > tbody {
    overflow-y: auto;
    max-height: 60vh;
}

.table-fixed > tbody > tr > td,
.table-fixed > thead > tr > th {
    float: left;
    border-bottom: none;
}

.performance-overview.table-fixed > tbody > tr > td,
.performance-overview.table-fixed > thead > tr > th {
    font-size: 0.55rem;
    min-width: 60px;
}
