.topPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}
.topPanel .loginSpan:hover {
  font-weight: 800;
  cursor: pointer;
}
.topPanel a {
  text-decoration: none;
  color: inherit;
  outline: 0;
}
.topPanel img {
  height: 50px;
}

.back-panel {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 50px;
  transition: background-color 500ms ease-in-out;
  background-color: #198754;
  color: white;
  font-weight: 600;
}
.back-panel:hover {
  transition: background-color 500ms ease-in-out;
  background-color: #53a76c;
  color: #ddd;
}

@media (max-width: 600px) {
  .company-logo {
    display: none;
  }
}
