.today_info .building .info {
  background-color: #eee;
}
.today_info .building .info span {
  width: 30%;
  display: inline-block;
}
.today_info .ellipsis {
  text-overflow: ellipsis;
  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
}
.today_info .order .info {
  background-color: rgba(0, 255, 0, 0.2);
}
.today_info .order, .today_info .building {
  border-left: 1px solid #ccc;
}
.today_info .order .info > span, .today_info .building .info > span {
  margin-right: 35px;
}
.today_info .info {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 10px;
  margin-bottom: 5px;
}
.today_info .children {
  margin-left: 20px;
}
.today_info .employee {
  padding: 8px 10px;
}
.today_info .employee > div {
  width: 16%;
  display: inline-block;
}
.today_info .hidden {
  display: none;
}
.today_info div.sortable {
  cursor: pointer;
  user-select: none;
}
.today_info div.sortable:hover {
  background-color: #eee;
}

.choose-employee-form {
  padding: 40px;
  box-shadow: 0 0 16px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 25px;
}
