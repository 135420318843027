.sugg-container {
  max-height: 250px;
  overflow-y: scroll;
  border: 1px solid #eee;
  box-shadow: 0 12px 28px 4px rgba(0, 0, 0, 0.75);
}

.working-hour-detail {
  padding-bottom: 100px;
}
.working-hour-detail .month-view {
  margin-top: 10px;
}
.working-hour-detail .month-view .table-responsive {
  overflow: inherit;
}
.working-hour-detail .month-view tr.collision {
  background-color: rgba(255, 0, 0, 0.2);
  padding-top: 0;
  padding-bottom: 0;
}
.working-hour-detail .month-view th {
  padding: 5px;
}
.working-hour-detail .month-view td {
  padding: 5px 0;
  position: relative;
  max-width: 150px;
}
.working-hour-detail .spz {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.working-hour-detail .day-info {
  vertical-align: middle !important;
  text-align: center;
  width: 80px;
}
.working-hour-detail tr {
  cursor: pointer;
}
.working-hour-detail tr:hover {
  background-color: #eee;
}
.working-hour-detail tr.work-in-day.bordered {
  border-top: 1.5px solid black !important;
}
.working-hour-detail thead {
  font-size: 11pt;
  text-align: center;
}
.working-hour-detail thead th, .working-hour-detail .work-cell {
  text-align: center;
}
.working-hour-detail td {
  vertical-align: middle !important;
  text-align: center;
}
.working-hour-detail h1 {
  margin-bottom: 25px !important;
}
.working-hour-detail td input {
  margin: 0;
  padding: 0;
  text-align: center;
  max-width: 80px;
  border: none;
}
.working-hour-detail .selector {
  font-size: 12pt;
  border-style: none;
  text-align: center;
  text-align-last: center;
}
.working-hour-detail .form-modal-body {
  padding: 10px 40px 20px 40px !important;
}
.working-hour-detail .month-accept {
  padding: 10px 20px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.65);
  margin-bottom: 40px;
}
.working-hour-detail .weekend {
  background-color: rgba(116, 252, 96, 0.61);
}
.working-hour-detail .row-error {
  background-color: rgba(255, 0, 0, 0.2);
}
.working-hour-detail .row-holiday {
  background-color: rgba(0, 129, 11, 0.67);
}

h2, h3 {
  margin-bottom: 25px !important;
}

.choose-employee-form {
  padding: 40px;
  box-shadow: 0 0 16px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 25px;
}

.statistics {
  background-color: rgba(54, 199, 54, 0.28);
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 25px;
  text-align: left;
}

.spacing {
  display: flex;
  justify-content: space-between;
}

.cell-error {
  background-color: rgba(255, 0, 10, 0.2);
}
