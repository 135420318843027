.employees .registerForm .title {
  text-align: center;
  color: #777;
  margin-bottom: 20px;
  margin-top: 0;
}
.employees .registerForm .closeBox {
  text-align: right;
  font-size: 24px;
}
.employees .registerForm .closeBox svg {
  cursor: pointer;
}
.employees .registerForm .closeBox svg:hover {
  color: #888;
}
.employees .registerForm .avatarSection {
  display: flex;
  justify-content: center;
}
.employees .filterBox {
  margin-bottom: 20px;
  background-color: #eee;
  padding: 20px;
  margin-top: 15px;
}
.employees .employees-box {
  padding-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.employees .employees-box .employee {
  padding: 15px;
  width: 300px;
  box-shadow: 0 0 20px 0.5px rgba(0, 0, 0, 0.15);
  margin-bottom: 25px;
  margin-right: 25px;
  position: relative;
}
.employees .employees-box .employee .moreOptionsNav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: right;
  padding-right: 5px;
  padding-top: 10px;
  font-size: 18px;
}
.employees .employees-box .employee .moreOptionsNav svg {
  cursor: pointer;
}
.employees .employees-box .employee .avatar {
  display: flex;
  justify-content: center;
}
.employees .employees-box .employee .avatar img {
  height: 80px;
}
.employees .employees-box .employee .name {
  text-align: center;
}
.employees .employees-box .employee .phone {
  text-align: center;
  color: #aaa;
}
.employees .employees-box .employee .info {
  text-align: center;
}

.employees-form label.file-label {
  transition: box-shadow 250ms ease-in-out;
  margin: 10px 0;
  background-color: rgb(67, 183, 81);
  padding: 8px 10px;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px 2px rgba(148, 148, 148, 0.63);
  cursor: pointer;
  color: white;
}
.employees-form .file-label:hover {
  transition: box-shadow 250ms ease-in-out;
  background-color: #42c552;
  box-shadow: 0 0 10px 2px rgba(148, 148, 148, 0);
}
.employees-form .scaleButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 5px;
  width: 35px;
  height: 35px;
}

.text-right {
  text-align: right;
  font-weight: bold;
}

.closeBox {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}
.closeBox svg {
  cursor: pointer;
}
.closeBox .closeButton style {
  cursor: pointer;
}
