.working-hour-detail .react-autosuggest__container {
  margin: 0;
  padding: 0;
}
.working-hour-detail .react-autosuggest__container input {
  height: 24px;
  margin: 0;
  padding: 0;
}
.working-hour-detail .react-autosuggest__suggestions-container {
  position: absolute;
  top: 24px;
  width: 200px;
  background: white;
  z-index: 99999;
  word-wrap: break-word;
}
.working-hour-detail .react-autosuggest__suggestions-container ul {
  margin: 0;
  list-style: none;
  padding-left: 0;
}
.working-hour-detail .react-autosuggest__suggestions-container .sugg {
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 1px solid #eee;
  width: 100%;
}
.working-hour-detail .react-autosuggest__suggestions-container .sugg > * {
  display: inline-block;
}
.working-hour-detail .react-autosuggest__suggestions-container .sugg > svg {
  margin-right: 8px;
}
.working-hour-detail .react-autosuggest__suggestions-container .sugg p {
  word-break: break-all;
}
.working-hour-detail .react-autosuggest__suggestions-container .sugg.history {
  display: flex;
  background-color: #ccc;
}
.working-hour-detail .react-autosuggest__suggestions-container .sugg:hover {
  background: #eee;
}
.working-hour-detail .custom-dropdown {
  width: 200px;
  position: absolute;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background-color: #eee;
  height: 100px;
}
.working-hour-detail .custom-dropdown::before {
  content: "";
  position: absolute;
  left: 15px;
  top: -20px;
  border: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid #aaa;
}
.working-hour-detail .vacation {
  margin: 15px auto;
  padding: 20px 10px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
}
.working-hour-detail .vacation input[type=checkbox] {
  margin-bottom: 20px;
}
.working-hour-detail .horizontal-slider {
  width: 100%;
  max-width: 100%;
  height: 50px;
  border: 1px solid grey;
}
.working-hour-detail .horizontal-slider .bar {
  top: 20px;
  height: 10px;
}
.working-hour-detail .horizontal-slider .handle {
  top: 1px;
  width: 50px;
  height: 48px;
  line-height: 48px;
}
.working-hour-detail .handle {
  font-size: 0.9em;
  text-align: center;
  background-color: black;
  color: white;
  cursor: pointer;
}
.working-hour-detail .handle.active {
  background-color: grey;
}
.working-hour-detail .bar {
  position: relative;
  background: #ddd;
}
.working-hour-detail .bar.bar-1 {
  background: #0f0;
}
.working-hour-detail .bar.bar-2 {
  background: #ddd;
}
.working-hour-detail #horizontal-0,
.working-hour-detail #horizontal-1, .working-hour-detail #horizontal-2, .working-hour-detail #horizontal-3,
.working-hour-detail #vertical {
  margin: 20px 10px;
}
.working-hour-detail #horizontal-2 .bar.bar-2 {
  background: #ddd;
}
